import Service from "../Service";

const resource = "parkingregister/";

export default {
  save(par, requestID) {
    return Service.post(resource + "save", par, {
      params: { requestID: requestID },
    });
  },
  
  pagination(par, requestID) {
    return Service.post(resource + "pagination", par, {
      params: { requestID: requestID },
    });
  },
  
  showPdf(par, requestID) {    
    return Service.post(resource + "showPdf", par, {
      responseType: "blob",
      headers: {
          "Content-Type": "application/json",
      },
      params: { requestID: requestID },
  });
  },
};