import Service from "../Service";

const resource = "mngparkingpermit/";

export default {
  save(park, requestID) {
    return Service.post(resource + "save", park, {
      params: { requestID: requestID },
    });
  },
//   saveMassive(list, requestID) {
//     return Service.post(resource + "savemassive", list, {
//       params: { requestID: requestID },
//     });
//   },
  pagination(par, requestID) {
    return Service.post(resource + "pagination", par, {
      params: { requestID: requestID },
    });
  },
  ValidateAccess(par, requestID){
    return Service.post(resource + "validateaccess", par, {
      params: { requestID: requestID },
    });
  },
  ParkingPermitDocument(par, requestID){
    return Service.post(resource + "getdocument", par, {
      params: { requestID: requestID },
    });
  }
//   get(par, requestID) {
//     return Service.post(resource, par, {
//       params: { requestID: requestID },
//     });
//   },
};
