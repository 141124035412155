
<template>
    <v-card inline>
        <v-card-title>
            <v-tabs v-model="currentItem">
                <v-tab href="#EntryRegister">
                    <v-row>
                        <v-col  >
                            <v-chip
                                style="margin: 0px"
                                small
                                :color="'info'"
                                >{{ "Ingreso QR" }} 
                            </v-chip>
                        </v-col>
                    </v-row>
                </v-tab>
                <v-tab href="#EntryListRegister">
                    <v-row>
                        <v-col  >
                            <v-chip
                                style="margin: 0px"
                                small
                                :color="'info'"
                                >{{ "Ingresos" }} 
                            </v-chip>
                        </v-col>
                    </v-row>          
                </v-tab>
            </v-tabs>
        </v-card-title>   
        <v-card-text>
            <v-tabs-items v-model="currentItem">
                <v-tab-item :value="'EntryRegister'">                
                    <v-card>                        
                        <v-card-text>
                            <v-row justify="center" style="margin: auto;">
                                <v-col>
                                    <s-select-definition 
                                        label="Tipo de Ingreso"
                                        :def="1458"
                                        v-model="TypeImcome"
                                        @change="SetterValue()">

                                    </s-select-definition>
                                </v-col>
                            </v-row>
                            <v-row justify="center" style="margin:auto">
                                <v-col
                                    align="center"
                                    cols="12"
                                    lg="12"
                                    md="12"
                                >   
                                    <s-scanner-qr
                                        :config="configScann"
                                        return-object 
                                        @onValue="onValue($event)"
                                        :readonly="false"
                                        :autofocus="false"
                                        :clearInput="clearInput"
                                        @input="searchitemsSend($event)"
                                        >
                                    </s-scanner-qr>
                                </v-col>
                            </v-row>
                            <v-row v-if="Person.MessaggeAccess" class="d-none d-sm-flex">
                                <v-col style="padding-top: 10px">
                                    <v-card rounded="" :color="Person.color">
                                        
                                            <v-row justify="center"> 
                                                <p style="padding: 10px; color: whitesmoke; margin-top: 10px; font-size: 48px; ">
                                                    <b>{{Person.MessaggeAccess}}</b>
                                                </p>
                                                
                                            </v-row>
                                            <v-row v-if="Person.Messagge != null" justify="center"> 
                                                <p style="padding: 10px; color: whitesmoke; margin-top: 10px; font-size: 12px; ">
                                                    {{ Person.Messagge }}
                                                </p>  
                                            </v-row>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row justify="center" v-if="visibleListVehicles">
                                <v-col style="align-items: center;" cols="12">                    
                                    <v-item-group v-model="selectVehicle"  active-class="primary">
                                        <div class="text-h6 mb-2">Vehiculos del Personal</div>
                                            <v-container>
                                                <v-row >
                                                    <v-col 
                                                        class="fm-card-container"
                                                        cols="1"
                                                        v-for="item in ListVehicles"
                                                            v-bind:key="item.PkvID"
                                                            :value="item">
                                                        <v-item 
                                                            v-slot="{ active, toggle }"
                                                            >
                                                            
                                                                <v-chip                                         
                                                                    :color="active ? 'primary' : ''" @click="toggle"> 
                                                                    {{ item.LicensePlate.toLocaleUpperCase() }} 
                                                                </v-chip>
                                                        </v-item>  
                                                    </v-col>
                                                    <v-col align-self="end">
                                                        <v-btn 
                                                            
                                                            fab
                                                            x-small                                        
                                                            depressed color="primary"  
                                                            @click="ValidateVehicle()">
                                                            <v-icon>mdi-send</v-icon>
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-container>     
                                    </v-item-group>
                                </v-col>
                            </v-row> 
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item :value="'EntryListRegister'">
                    <access-list  />
                </v-tab-item> 
            </v-tabs-items>
        </v-card-text>
    </v-card>
</template>

<script>
    import _sParking from "@/services/Management/ParkingPermit";
    import _sParkingRegister from "@/services/Management/ParkingRegister.js";
    import AccessList from './ValidateAccessList.vue'
    export default{
        components:{AccessList},
        data(){
            return{
                clearInput:false,
                configScann: {}, 
                Person: {},
                TypeImcome: 0,
                currentItem: "tab-Funciones",
                DateSearch: '',
                ListVehicles: [],
                DocumentPerson: '',
                selectVehicle: 0,
                LicensePlate: '',
                visibleListVehicles: false,                
            }
        },
        methods:{
            onValue(val)
            {     
                if(this.ListVehicles.length > 0)
                {
                    if(this.LicensePlate.length == 0)
                    {
                        this.LicensePlate = this.ListVehicles[this.selectVehicle].LicensePlate;
                    }          
                    if(this.TypeImcome == 0 || this.TypeImcome == undefined)
                    {
                        this.$fun.alert("Seleccione tipo de ingreso", "info");
                        return;
                    }
                    _sParking.ValidateAccess({PrsDocumentNumber : val, TypeImcome : this.TypeImcome, LicensePlate :this.LicensePlate }, this.$fun.getUserID()).then(r => {
                        if(r.status == 200)
                        {              
                            if(r.data.Register == 1)
                            {
                                this.Person = r.data;
                                this.Person.TypeImcome = this.TypeImcome;
                                this.Person.LicensePlate = this.LicensePlate;
                                _sParkingRegister.save(this.Person, this.$fun.getUserID()).then( r => {
                                    if(r.status == 200)
                                    {
                                        console.log(r.data);
                                    }      
                                                            
                                }).then( v => {                                
                                    console.log("error", '2');
                                });
                            }else{
                                this.Person = r.data;
                            }
                        }
                    });
                }
            },
            SetterValue(){
                this.Person = {};
            },
            searchitemsSend(val)
            {
                this.visibleListVehicles = false;
                if(val.length == 8)
                {
                    
                    this.DocumentPerson = val;
                    _sParking.ParkingPermitDocument({PrsDocumentNumber : this.DocumentPerson }, this.$fun.getUserID()).then(r => {
                    if(r.status == 200)
                    {         
                        this.ListVehicles = r.data.ListVehicle;
                        this.visibleListVehicles = true;
                    }
                    });
                }
            },
            ValidateVehicle()
            {        
                if(this.ListVehicles.length > 0)
                {
                    this.LicensePlate = this.ListVehicles[this.selectVehicle].LicensePlate;
                    this.onValue(this.DocumentPerson);
                }     
            }
        }
    }
</script>