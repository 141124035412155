<template>
    <v-card dense >
        <v-card-text>
            <v-row>
                <v-col cols="2">
                    <s-date label="Fecha Para Generar Reporte" v-model="DateSearch"></s-date>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">         
                    <s-crud                                 
                        
                        edit
                        :filter="filter"                                    
                        
                        :config="config"            
                        @addEvent="addEvent()"  
                        @save="save($event)"
                        pdf
                        @pdf="downloadPdf()"
                        >
                        <template v-slot="props">                            
                            <v-container>
                                <v-row>
                                    <v-col>
                                        <s-text
                                            v-model="props.item.PrsDocumentNumber"
                                            label="Documento">

                                        </s-text>
                                    </v-col>
                                    <v-col>
                                        <s-date
                                            v-model="props.item.DateIncome"
                                            label="Entrada">

                                        </s-date>
                                    </v-col>
                                    <v-col 
										><s-date
											label="Fecha Inicio"
											v-model="props.item.DateExitLunch"
										></s-date>
									</v-col>
                                    <v-col 
										><s-date
											label="Fecha Inicio"
											v-model="props.item.DateIncomeLunch"
										></s-date>
									</v-col>
                                    <v-col>
                                        <s-date
                                            v-model="props.item.DateExit"
                                            label="Salida">

                                        </s-date>
                                    </v-col>
                                </v-row>                            
                            </v-container>
                        </template>
                        <template v-slot:filter>
							<v-container>
								<v-row justify="center" class="s-col-form">
									
									<v-col justify="center" lg="3" cols="12"
										><s-date
											label="Fecha Inicio"
											v-model="filter.DateBegin"
										></s-date>
									</v-col>
									<v-col lg="3" cols="12"
										><s-date
											label="Fecha Fin"
											v-model="filter.DateEnd"
										></s-date>
									</v-col>
								</v-row>
								
								<v-row class="s-col-form">
									
								</v-row>
							</v-container>
						</template>
                    </s-crud>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
    import _sParkingRegister from "@/services/Management/ParkingRegister.js";

    export default{
        data: () => {
            return{
                filter: {
                    DateBegin: null,
                    DateEnd: null, 
                },
                config:{
                    service: _sParkingRegister,                    
                    headers:[
                        {text: "ID", value: "PprID",  align: "center"},
                        {text: "Documento", value: "PrsDocumentNumber",  align: "center"},
                        // {text: "Placa", value: "LicensePlate",  align: "center"},
                        {text: "Nombre", value: "NtpName",  align: "center"},                        
                        {text: "Apellido P.", value: "NtpPaternalSurname",  align: "center"},
                        {text: "Apellidos M.", value: "NtpMaternalSurname",  align: "center"},
                        {text: "Entrada", value: "DateIncomeString",  align: "center"},
                        {text: "Salida Almuerzo", value: "DateExitLunchString",  align: "center"},
                        {text: "Entrada Almuerzo", value: "DateIncomeLunchString",  align: "center"},
                        {text: "Salida", value: "DateExitString",  align: "center"},
                    ],
                    
                    model:{
                        PprID: "ID",
                    },
                },
                DateSearch: '',
            }
        },
        methods:{
            save(item){
                item.save();
            },
            addEvent(){

            },
            downloadPdf(){
                _sParkingRegister.showPdf({Date : this.DateSearch}, this.$fun.getUserID()).then((r) => {
                    if(r.status == 200)
                    {
                        this.$fun.downloadFile(r.data, this.$const.TypeFile.PDF, "INGRESOS Y SALIDAS DE VEHICULOS");
                        console.log(r.data);
                    }
                })
            }
        }
    }
</script>